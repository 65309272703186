import React, { useEffect } from 'react';
import { useFormik, FormikProvider, Form, Field } from 'formik';
import { TextField } from '@mui/material';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Card, CardContent, Grid } from '@mui/material';

interface CandidateDetailFormProps {
    employeeDataVal: any;
    onSubmitting: (formVal: any) => void;
}

const CandidateDetailSchema = Yup.object().shape({
    candidateName: Yup.string(),
    fatherName: Yup.string(),
    mobileNumber: Yup.string(),
    address: Yup.string(),
    landmark: Yup.string(),
});

const CandidateDetailForm: React.FC<CandidateDetailFormProps> = ({
    employeeDataVal,
    onSubmitting,
}) => {
    const employeeData = employeeDataVal?.employee_case_data?.[0] || {};
    const FULL_NAME = [
        employeeData?.FIRST_NAME ?? '',
        employeeData?.MIDDLE_NAME ?? '',
        employeeData?.LAST_NAME ?? '',
    ]
        .filter(Boolean)
        .join(' ');

    const formik = useFormik({
        initialValues: {
            candidateName: FULL_NAME, // Assign the calculated fullName here
            fatherName: employeeData?.FATHER_NAME ?? '',
            mobileNumber: employeeData?.CONTACT_NUMBER ?? '',
            landmark: employeeData?.LANDMARK ?? '',
            address: employeeData?.ADDRESS ?? '',
        },
        validationSchema: CandidateDetailSchema,
        onSubmit: (values) => {
            onSubmitting(values);
        },
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, isValid } = formik;

    useEffect(() => {
        formik.resetForm({
            values: {
                candidateName: FULL_NAME,
                fatherName: employeeData?.FATHER_NAME ?? '',
                mobileNumber: employeeData?.CONTACT_NUMBER ?? '',
                landmark: employeeData?.LANDMARK ?? '',
                address: employeeData?.ADDRESS ?? '',
            },
        });
    }, [employeeDataVal]);

    return (
        <FormikProvider value={formik}>
            <Form className="w-100" autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        maxWidth: '100%',
                        margin: 'auto',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                autoComplete="candidateName"
                                label="Candidate Name"
                                {...getFieldProps('candidateName')}
                                error={Boolean(touched.candidateName && errors.candidateName)}
                                helperText={
                                    touched.candidateName ? (errors.candidateName as string) : ''
                                }
                                variant="outlined"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                autoComplete="fatherName"
                                label="Father Name"
                                {...getFieldProps('fatherName')}
                                error={Boolean(touched.fatherName && errors.fatherName)}
                                helperText={touched.fatherName ? (errors.fatherName as string) : ''}
                                variant="outlined"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                autoComplete="mobileNumber"
                                label="Mobile Number"
                                {...getFieldProps('mobileNumber')}
                                error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                                helperText={
                                    touched.mobileNumber ? (errors.mobileNumber as string) : ''
                                }
                                variant="outlined"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Address"
                                multiline
                                rows={2} // You can adjust the number of rows as needed
                                {...getFieldProps('address')}
                                error={Boolean(touched.address && errors.address)}
                                helperText={touched.address ? (errors.address as string) : ''}
                                variant="outlined"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                autoComplete="landmark"
                                label="Landmark"
                                {...getFieldProps('landmark')}
                                error={Boolean(touched.landmark && errors.landmark)}
                                helperText={touched.landmark ? (errors.landmark as string) : ''}
                                variant="outlined"
                                disabled
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        sx={{
                            position: 'fixed',
                            bottom: '1.875rem',
                            right: '1.875rem',
                        }}
                        type="submit"
                        variant="contained"
                        color="primary"
                    >
                        PROCEED
                    </Button>
                </Box>
            </Form>
        </FormikProvider>
    );
};

export default CandidateDetailForm;
