import React from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { TextField, TextFieldProps, FormControlLabel, Checkbox } from '@mui/material';
import dayjs from 'dayjs';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CustomFileUpload from '../components/CustomFileUpload';
import { RESIDENTIAL_STATUS_OPTIONS } from '../constants';

interface ResidentialFormProps {
    employeeDataVal: any;
    onSubmitting: (formVal: any) => void;
    onPrevious: (step: number) => void;
}

const ResidentialStatusSchema = Yup.object().shape({
    residentialStatus: Yup.string().required('Residential Status is required'),

    fromDate: Yup.date()
        .nullable()
        .test('check-from-date', 'From Date is required if not From Birth', function (value) {
            const { checkFromBirth } = this.parent;
            return checkFromBirth || value !== null;
        }),

    toDate: Yup.date()
        .nullable()
        .test('check-to-date', 'To Date is required if not Till Date', function (value) {
            const { checkTillDate } = this.parent;
            return checkTillDate || value !== null;
        }),

    checkFromBirth: Yup.boolean(),
    checkTillDate: Yup.boolean(),
});

const ResidentialStatusForm: React.FC<ResidentialFormProps> = ({
    employeeDataVal,
    onSubmitting,
    onPrevious,
}) => {
    const employeeData = employeeDataVal?.employee_case_data?.[0] || {};
    // const [toDate, setToDate] = React.useState('');
    // const [fromDate, setFromDate] = React.useState('');
    const formik = useFormik({
        initialValues: {
            residentialStatus: employeeData?.HOUSE_STATUS,
            fromDate:
                employeeData?.PERIOD_OF_STAY_FROM != 'By birth'
                    ? dayjs(employeeData?.PERIOD_OF_STAY_FROM)
                    : null,
            toDate:
                employeeData?.PERIOD_OF_STAY_TO != 'Till date'
                    ? dayjs(employeeData?.PERIOD_OF_STAY_TO)
                    : null,
            checkFromBirth: employeeData?.PERIOD_OF_STAY_FROM == 'By birth',
            checkTillDate: employeeData?.PERIOD_OF_STAY_TO == 'Till date',
        },
        validationSchema: ResidentialStatusSchema,
        onSubmit: (values) => {
            onSubmitting(values);
        },
    });

    const { errors, touched, handleSubmit, getFieldProps, setFieldValue, values, isValid } = formik;

    const handleResidentialStatus = (event: SelectChangeEvent) => {
        setFieldValue('residentialStatus', event.target.value as string);
    };

    return (
        <FormikProvider value={formik}>
            <Form className="w-100" autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        maxWidth: '100%',
                        margin: 'auto',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ marginTop: '0.5rem' }}>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="residential-status-label">
                                            Residential Status
                                        </InputLabel>
                                        <Select
                                            labelId="residential-status-label"
                                            id="residential-status"
                                            {...getFieldProps('residentialStatus')}
                                            label="Residential Status"
                                            error={Boolean(
                                                touched.residentialStatus &&
                                                    errors.residentialStatus,
                                            )}
                                        >
                                            {RESIDENTIAL_STATUS_OPTIONS.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: '0.5rem' }}>
                            <Grid container>
                                <Grid item xs={7} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                sx={{ width: '100%' }}
                                                label="From"
                                                value={formik.values.fromDate}
                                                disabled={values.checkFromBirth}
                                                onChange={(date: any) => {
                                                    setFieldValue('fromDate', date);
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    {!values.fromDate && !values.checkFromBirth && (
                                        <span
                                            style={{
                                                color: '#d32f2f',
                                                fontWeight: '400',
                                                fontSize: '0.725rem',
                                            }}
                                        >
                                            From Date is required or check from birth
                                        </span>
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ margin: '0.625rem 0 0 1rem' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                color="primary"
                                                checked={formik.values.checkFromBirth}
                                                onChange={(event) => {
                                                    setFieldValue('fromDate', null);
                                                    setFieldValue(
                                                        'checkFromBirth',
                                                        event.target.checked,
                                                    );
                                                    //setFieldValue('fromDate', '');
                                                }}
                                            />
                                        }
                                        label="From Birth"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: '0.5rem' }}>
                            <Grid container>
                                <Grid item xs={7} sm={6}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                sx={{ width: '100%' }}
                                                label="To"
                                                value={formik.values.toDate}
                                                disabled={values.checkTillDate}
                                                onChange={(date: any) => {
                                                    setFieldValue('toDate', date);
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                    {!values.toDate && !values.checkTillDate && (
                                        <span
                                            style={{
                                                color: '#d32f2f',
                                                fontWeight: '400',
                                                fontSize: '0.725rem',
                                            }}
                                        >
                                            To Date is required or check till Date
                                        </span>
                                    )}
                                </Grid>
                                <Grid item xs={4} sx={{ margin: '0.625rem 0 0 1rem' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                color="primary"
                                                checked={formik.values.checkTillDate}
                                                onChange={(event) => {
                                                    setFieldValue('toDate', null);
                                                    setFieldValue(
                                                        'checkTillDate',
                                                        event.target.checked,
                                                    );
                                                    //setFieldValue('toDate', '');
                                                }}
                                            />
                                        }
                                        label="Till Date"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div
                        style={{
                            position: 'fixed',
                            bottom: '1.875rem',
                            right: '1.875rem',
                            display: 'flex',
                            gap: '3',
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            sx={{ mr: 1 }}
                            onClick={() => onPrevious(1)}
                        >
                            PREVIOUS
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                        >
                            NEXT
                        </Button>
                    </div>
                </Box>
            </Form>
        </FormikProvider>
    );
};

export default ResidentialStatusForm;
