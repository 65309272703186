import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import LocationOffIcon from '@mui/icons-material/LocationOff';

interface GeoLocationDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    onCheckSettings: () => void; // Accept onCheckSettings as a prop
}

const GeoLocationDialog: React.FC<GeoLocationDialogProps> = ({
    isOpen,
    handleClose,
    onCheckSettings,
}) => {
    const handleGetLocation = () => {
        onCheckSettings();
        handleClose();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '& .MuiDialog-paper': {
                    height: '20rem',
                },
            }}
        >
            <DialogContent
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
                    <LocationOffIcon sx={{ height: '5rem', width: '5rem', color: '#E53935' }} />
                    <div
                        style={{
                            fontWeight: 'bold',
                            fontSize: '1.25rem',
                            textAlign: 'center',
                            margin: '1.25rem 0',
                        }}
                    >
                        Geo-Location is OFF, Please Turned ON
                    </div>
                    <Button
                        sx={{
                            fontSize: '18px',
                            borderRadius: '1.875rem',
                            padding: '0.15rem 0.65rem',
                            background: '#D9D9D9',
                            color: '#424242',
                            border: '1px solid #D9D9D9',
                            fontWeight: 'bold',
                        }}
                        variant="outlined"
                        onClick={handleGetLocation} // Call the handleGetLocation method
                    >
                        Check Settings
                    </Button>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
};

export default GeoLocationDialog;
