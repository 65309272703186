import { Components, Theme } from '@mui/material/styles';

const Dialog = (theme: Theme): Components<Theme> => ({
    MuiDialog: {
        styleOverrides: {
            paper: {
                borderRadius: '10px',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                width: '888px', // Customize width
                height: '482px', // Customize height
            },
        },
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                padding: theme.spacing(2, 3),
                backgroundColor: theme.palette.background.default,
                borderBottom: `1px solid ${theme.palette.divider}`,
                fontSize: theme.typography.h6.fontSize,
            },
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                padding: theme.spacing(3),
                backgroundColor: theme.palette.background.default,
                fontSize: theme.typography.body1.fontSize,
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                padding: theme.spacing(2),
                backgroundColor: theme.palette.background.default,
                justifyContent: 'flex-end',
            },
        },
    },
});

export default Dialog;
