import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';
import routes from '../../routes';
import TitleWrapper from '../../components/TitleWrapper';

export default function HomeNavbar() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();
    const currentRoute = routes(false).find((route: any) => route.path === location.pathname);

    return (
        <Box sx={{ flexGrow: 1, height: '4rem' }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ flexGrow: 1 }}
                        style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'left' }}
                    >
                        <TitleWrapper title={currentRoute?.title || 'DIGITAL VERFICATION'}>
                            <h1>{currentRoute?.title}</h1>
                        </TitleWrapper>
                    </Typography>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
