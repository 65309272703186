import React, { createContext, useContext, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import HomeNavbar from './HomeNavbar';

interface TitleContextProps {
    title: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
}

const TitleContext = createContext<TitleContextProps | undefined>(undefined);

const APP_BAR_MOBILE = 10;
const APP_BAR_DESKTOP = 20;

const RootStyle: any = styled('div')({
    display: 'flex',
    flexFlow: 'column',
    minHeight: '100%',
    overflow: 'hidden',
});

const MainStyle: any = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'hidden',
    minHeight: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.up('lg')]: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

export default function HomeLayout() {
    // const [open, setOpen] = useState(false);
    return (
        <RootStyle>
            <HomeNavbar />
            <MainStyle>
                <Outlet />
            </MainStyle>
        </RootStyle>
    );
}
