import * as React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import StepperCard from '../components/stepperCard';
import CandidateDetailForm from './CandidateDetailForm';
import VerfiedByForm from './VerifiedByForm';
import { Card, CardContent } from '@mui/material';
import ResidentialStatusForm from './ResidentialStatusForm';
import UploadPictureProofForm from './UploadPictureProofForm';
import GeoLocationDialog from '../components/Dailog/GeolocationDailog';
import CheckEmployeeValidDailog from '../components/Dailog/CheckEmployeeValidDailog';
import Server from '../api/index';
import dayjs from 'dayjs';
import CustomSnackbar from '../components/CustomSnackbar';

const steps = ['Candidate Details', 'Verified By', 'Residential Status', 'Upload Picture Proof'];

export default function HomePage() {
    const navigate = useNavigate();
    let savedSubmitData = localStorage.getItem('submit_values')
        ? JSON.parse(localStorage.getItem('submit_values') as string) // Ensure it's a string
        : ({} as any);
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
    const [isGeolocationDialogOpen, setIsGeolocationDialogOpen] = React.useState(false);
    const [isEmployeeValidDialogOpen, setIsEmployeeValidDialogOpen] = React.useState(false);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = React.useState(true); // New state
    const [employeeData, setEmployeeData] = React.useState({} as any);
    const [searchParams] = useSearchParams();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState('');
    const [snackbarType, setSnackbarType] = React.useState<'success' | 'error'>('success');
    const [submitValues, setSubmitValues] = React.useState(savedSubmitData);

    const uid = searchParams.get('uid')?.replace(/"/g, '') || '';

    const totalSteps = () => steps.length;

    const completedSteps = () => Object.keys(completed).length;

    const isLastStep = () => activeStep === totalSteps() - 1;

    const allStepsCompleted = () => completedSteps() === totalSteps();

    const showSnackbar = (message: string, type: 'success' | 'error') => {
        setSnackbarMessage(message);
        setSnackbarType(type);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    React.useEffect(() => {
        if (activeStep == 0) {
            setIsEmployeeValidDialogOpen(true);
        } else {
            const savedData: any = localStorage.getItem('EmployeeData')
                ? JSON.parse(localStorage.getItem('EmployeeData') ?? '{}')
                : {};
            setEmployeeData(savedData);
        }

        // const savedStep = localStorage.getItem('step');
        // const parsedStep = savedStep ? JSON.parse(savedStep) : 0;
        // setActiveStep(parsedStep);
    }, []);

    const handleNext = async (activeStep: number) => {
        if (activeStep === 0) {
            if (navigator.permissions) {
                navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                    if (result.state === 'granted') {
                        getGeolocation();
                    } else if (result.state === 'prompt') {
                        // Trigger the geolocation prompt
                        setIsGeolocationDialogOpen(true);
                    } else if (result.state === 'denied') {
                        // If denied, show your dialog
                        setIsGeolocationDialogOpen(true);
                    }
                });
            } else {
                console.log('Permissions API not supported');
            }
        } else if (activeStep === 1) {
            const newActiveStep = activeStep + 1;
            setActiveStep(newActiveStep);
            localStorage.setItem('step', JSON.stringify(newActiveStep));
        } else {
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
            setActiveStep(newActiveStep);
            localStorage.setItem('step', JSON.stringify(newActiveStep));
        }
    };

    function getGeolocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            console.log('Geolocation not supported');
        }
    }

    function success(position: any) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        employeeData.employee_case_data[0].LATITUDE = latitude;
        employeeData.employee_case_data[0].LONGITUDE = longitude;
        localStorage.setItem('EmployeeData', JSON.stringify(employeeData));
        setEmployeeData(employeeData);

        submitValues.geolocation = {
            LATITUDE: latitude,
            LONGITUDE: longitude,
        };

        setSubmitValues(submitValues);
        localStorage.setItem('submit_values', JSON.stringify(submitValues));

        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;

        setActiveStep(newActiveStep);
        localStorage.setItem('step', JSON.stringify(newActiveStep));
    }

    const onSetGeoLocation = () => {
        getGeolocation();
    };

    function error() {
        setIsGeolocationDialogOpen(true);
    }
    const handleCloseDialog = () => {
        setIsGeolocationDialogOpen(false);
    };

    const handleBack = (activeStep: number) => {
        setActiveStep(activeStep);
        localStorage.setItem('step', JSON.stringify(activeStep));
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
        localStorage.setItem('step', JSON.stringify(step));
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    const handleCloseEmployeeValidDialog = () => {
        setIsEmployeeValidDialogOpen(false);
    };

    const handleEmployeeValid = (values: any) => {
        const formData = new FormData();
        formData.append('case_uid', values?.case_uid); // Replace with actual value
        formData.append('mobile_no', values?.mobile_no); // Replace with actual value
        Server.post(`/checkEmployeeValidLink`, formData)
            .then((res: any) => {
                if (res.data.meta.status === 200 && !res.data.meta.is_error) {
                    localStorage.setItem('EmployeeData', JSON.stringify(res?.data?.data));
                    setEmployeeData(res?.data?.data);
                    handleCloseEmployeeValidDialog();
                    showSnackbar(res.data.meta.message, 'success');
                    let employeeFormData = res?.data?.data?.employee_case_data[0];
                    if (employeeFormData?.LINK_STATUS != 0 && employeeFormData?.LINK_STATUS == 1) {
                        navigate('/form-submitted');
                        return;
                    }
                    submitValues.credentials = {
                        case_uid: values?.case_uid,
                        mobile_no: values?.mobile_no,
                        CASE_ID: employeeFormData?.CASE_ID,
                    };
                    setSubmitValues(submitValues);
                    localStorage.setItem('submit_values', JSON.stringify(submitValues));
                } else {
                    showSnackbar(res.data.meta.message, 'error');
                }
            })
            .catch((err) => {
                showSnackbar('Something went wrong', 'error');
            });
    };

    const onSubmitCandidateDetailForm = (formValue: any) => {
        submitValues.step_1 = {
            FULL_NAME: formValue?.candidateName,
            FATHER_NAME: formValue?.fatherName,
            CONTACT_NUMBER: formValue?.mobileNumber,
            LANDMARK: formValue?.landmark,
            ADDRESS: formValue?.address,
        };

        setSubmitValues(submitValues);
        localStorage.setItem('submit_values', JSON.stringify(submitValues));
        handleNext(0);
    };

    const onSubmitVerifiedByForm = (formValue: any, data: any) => {
        submitValues.step_2 = {
            VERIFIER_NAME: formValue?.VERIFIER_NAME,
            VERIFIER_CONTACT_NO: formValue?.VERIFIER_CONTACT_NO,
            VERIFIED_BY: formValue?.VERIFIED_BY,
            VERIFIER_RELATION: formValue?.VERIFIER_RELATION,
        };
        setSubmitValues(submitValues);
        localStorage.setItem('submit_values', JSON.stringify(submitValues));
        employeeData.employee_case_data[0].VERIFIER_NAME = formValue.VERIFIER_NAME;
        employeeData.employee_case_data[0].VERIFIER_CONTACT_NO = formValue.VERIFIER_CONTACT_NO;
        employeeData.employee_case_data[0].VERIFIED_BY = formValue.VERIFIED_BY;
        employeeData.employee_case_data[0].VERIFIER_RELATION = formValue.VERIFIER_RELATION;
        employeeData.employee_documents_data = data;
        localStorage.setItem('EmployeeData', JSON.stringify(employeeData));
        setEmployeeData(employeeData);
        handleNext(1);
    };

    const onSubmitResidentialStatusForm = (formValue: any) => {
        submitValues.step_3 = {
            HOUSE_STATUS: formValue.residentialStatus,
            PERIOD_OF_STAY_FROM: formValue.checkFromBirth
                ? 'By birth'
                : dayjs(formValue.fromDate).format('YYYY-MM-DD'),
            PERIOD_OF_STAY_TO: formValue.checkTillDate
                ? 'Till date'
                : dayjs(formValue.toDate).format('YYYY-MM-DD'),
        };
        setSubmitValues(submitValues);
        localStorage.setItem('submit_values', JSON.stringify(submitValues));

        employeeData.employee_case_data[0].HOUSE_STATUS = formValue.residentialStatus;
        if (formValue.checkFromBirth) {
            employeeData.employee_case_data[0].PERIOD_OF_STAY_FROM = 'By birth';
        } else {
            const formattedDate = dayjs(formValue.fromDate).format('YYYY-MM-DD');
            employeeData.employee_case_data[0].PERIOD_OF_STAY_FROM = formattedDate;
        }

        if (formValue.checkTillDate) {
            employeeData.employee_case_data[0].PERIOD_OF_STAY_TO = 'Till date';
        } else {
            const formattedDate = dayjs(formValue.toDate).format('YYYY-MM-DD');
            employeeData.employee_case_data[0].PERIOD_OF_STAY_TO = formattedDate;
        }
        localStorage.setItem('EmployeeData', JSON.stringify(employeeData));
        setEmployeeData(employeeData);
        handleNext(2);
    };

    const onSubmitUploadPictureProofForm = (formValue: any, data: any) => {
        const formData = new FormData();
        formData.append('case_id', data?.credentials.CASE_ID);
        formData.append('period_stay_from', data?.step_3.PERIOD_OF_STAY_FROM);
        formData.append('period_stay_to', data?.step_3.PERIOD_OF_STAY_TO);
        formData.append('verifier_relationship', data?.step_2.VERIFIER_RELATION);
        formData.append('verifier_name', data?.step_2.VERIFIER_NAME);
        formData.append('verifier_contact_no', data?.step_2.VERIFIER_CONTACT_NO);
        formData.append('verified_by', data?.step_2.VERIFIED_BY);
        formData.append('verifier_residential_status', data?.step_3.HOUSE_STATUS);
        formData.append('latitude', data?.geolocation.LATITUDE);
        formData.append('longitude', data?.geolocation.LONGITUDE);
        Server.post(`/submitDigitalVerificationForm`, formData)
            .then((res: any) => {
                if (res.data.meta.status === 200 && !res.data.meta.is_error) {
                    localStorage.removeItem('step');
                    localStorage.removeItem('submit_values');
                    localStorage.removeItem('EmployeeData');
                    showSnackbar(res.data.meta.message, 'success');
                    navigate('/form-submitted');
                } else {
                    showSnackbar(res.data.meta.message, 'error');
                }
            })
            .catch((err) => {
                showSnackbar('Digital Verification details failed', 'error');
            });
    };

    // Convert completed object to array of booleans
    const completedArray = steps.map((_, index) => completed[index] || false);

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <StepperCard
                    activeStep={activeStep}
                    steps={steps}
                    completed={completedArray}
                    handleStep={handleStep}
                />
                <Card
                    variant="outlined"
                    sx={{
                        marginTop: '1.25rem',
                        height: 'calc(100vh - 12rem)',
                        position: 'relative',
                        overflowY: 'auto',
                    }}
                >
                    <CardContent>
                        <React.Fragment>
                            {activeStep === 0 && (
                                <CandidateDetailForm
                                    employeeDataVal={employeeData}
                                    onSubmitting={onSubmitCandidateDetailForm}
                                />
                            )}
                            {activeStep === 1 && (
                                <VerfiedByForm
                                    employeeDataVal={employeeData}
                                    onSubmitting={onSubmitVerifiedByForm}
                                />
                            )}
                            {activeStep === 2 && (
                                <ResidentialStatusForm
                                    employeeDataVal={employeeData}
                                    onSubmitting={onSubmitResidentialStatusForm}
                                    onPrevious={handleBack}
                                />
                            )}
                            {activeStep === 3 && (
                                <UploadPictureProofForm
                                    employeeDataVal={employeeData}
                                    onSubmitting={onSubmitUploadPictureProofForm}
                                    onPrevious={handleBack}
                                />
                            )}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    pt: 2,
                                    position: 'absolute',
                                    bottom: '1.875rem',
                                    right: '1.875rem',
                                }}
                            >
                                {/* {activeStep !== 0 && activeStep !== 1 && activeStep !== 2 && (
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        PREVIOUS
                                    </Button>
                                )} */}
                                {/* {activeStep == 0 && (
                                    <Button
                                        variant="contained"
                                        onClick={() => handleNext(activeStep)}
                                        sx={{ mr: 1 }}
                                    >
                                        {activeStep === 0
                                            ? 'PROCEED'
                                            : activeStep === 3
                                            ? 'SUBMIT'
                                            : 'NEXT'}
                                    </Button>
                                )} */}
                            </Box>
                        </React.Fragment>
                    </CardContent>
                </Card>
            </Box>
            <GeoLocationDialog
                isOpen={isGeolocationDialogOpen}
                handleClose={handleCloseDialog}
                onCheckSettings={onSetGeoLocation}
            />

            <CheckEmployeeValidDailog
                isOpen={isEmployeeValidDialogOpen}
                handleClose={handleCloseEmployeeValidDialog}
                handleEmployeeFormSubmit={handleEmployeeValid}
                uidVal={uid}
            />

            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                type={snackbarType}
                onClose={handleCloseSnackbar}
            />
        </>
    );
}
