import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

const StyledAlert = styled(Alert)(({ theme, severity }) => ({
    ...(severity === 'success' && {
        backgroundColor: '#4caf50', // Green background
        color: '#fff', // White text
    }),
    ...(severity === 'error' && {
        backgroundColor: '#f44336', // Red background
        color: '#ffffff', // White text
    }),
}));

interface CustomSnackbarProps {
    open: boolean;
    message: string;
    type: 'success' | 'error';
    onClose: () => void;
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, message, type, onClose }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Position the Snackbar
        >
            <StyledAlert onClose={onClose} severity={type}>
                {message}
            </StyledAlert>
        </Snackbar>
    );
};

export default CustomSnackbar;
