import React, { forwardRef, useImperativeHandle } from 'react';
import { useFormik, FormikProvider, Form } from 'formik';
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Box,
    Button,
    Grid,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import * as Yup from 'yup';

import CustomFileUpload from '../components/CustomFileUpload';
import VerifyMobileDialog from '../components/Dailog/VerifyMobileDailog';
import { VERIFIED_BY_OPTIONS, VERIFIED_RELATION_OPTIONS } from '../constants';
import { PhotoTypesOptions } from '../enums';
import ImageItem from '../components/ImageItem';
import Server from '../api/index';

interface VerifiedByFormProps {
    employeeDataVal: any;
    onSubmitting: (formVal: any, data: any) => void;
}

const VerifiedBySchema = Yup.object().shape({
    VERIFIER_NAME: Yup.string().required('Name is required'),
    VERIFIED_BY: Yup.string().required('VerifiedBy is required'),
    VERIFIER_CONTACT_NO: Yup.string()
        .required('Contact Number is required')
        .min(10, 'Contact Number must be at least 10 characters'),
    VERIFIER_RELATION: Yup.string().required('Verified Person is required'),
    verified_photo_1: Yup.string(),
    verified_photo_obj_1: Yup.mixed().nullable(),
    verified_photo_2: Yup.string(),
    verified_photo_obj_2: Yup.mixed().nullable(),
});

const VerfiedByForm: React.FC<VerifiedByFormProps> = ({ employeeDataVal, onSubmitting }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const employeeData = employeeDataVal?.employee_case_data?.[0] || {};
    const [employeeDocuments, setEmployeeDocuments] = React.useState(
        employeeDataVal?.employee_documents_data || ([] as any),
    );
    let employeeOnlyVerifiedDocs =
        employeeDocuments && employeeDocuments.length > 0
            ? employeeDocuments.filter((item: any) => item.type_id == 4)
            : [];
    const [mobileNumber, setMobileNumber] = React.useState<string | null>(null);
    const [isVerifiedPhotoPresent, setIsVerifiedPhotoPresent] = React.useState<boolean>(false);
    const [isShown, setIsShown] = React.useState<boolean>(true);
    const [submitValues, setSubmitValues] = React.useState({} as any);
    const [credentialValues, setCredentialValues] = React.useState({} as any);

    const handleVerifyMobile = (contactNo: string) => {
        if (!contactNo || contactNo.length !== 10) {
            formik.setFieldError(
                'VERIFIER_CONTACT_NO',
                'Please enter a valid 10-digit mobile number.',
            );
            return;
        }

        formik.validateField('VERIFIER_CONTACT_NO').then(() => {
            if (!formik.errors.VERIFIER_CONTACT_NO) {
                setMobileNumber(contactNo);
                setIsVerifyDialogOpen(true);
            }
        });
    };

    const formik = useFormik({
        initialValues: {
            VERIFIER_NAME: employeeData?.VERIFIER_NAME ?? '',
            VERIFIER_CONTACT_NO: employeeData?.VERIFIER_CONTACT_NO ?? '',
            VERIFIED_BY: employeeData?.VERIFIED_BY ?? '',
            VERIFIER_RELATION: employeeData?.VERIFIER_RELATION ?? '',
            verified_photo_1: employeeOnlyVerifiedDocs[0] ? employeeOnlyVerifiedDocs[0]?.url : '',
            verified_photo_obj_1: employeeOnlyVerifiedDocs[0] ? employeeOnlyVerifiedDocs[0] : null,
            verified_photo_2: employeeOnlyVerifiedDocs[1] ? employeeOnlyVerifiedDocs[1]?.url : '',
            verified_photo_obj_2: employeeOnlyVerifiedDocs[1] ? employeeOnlyVerifiedDocs[1] : null,
        },
        validationSchema: VerifiedBySchema,
        onSubmit: (values) => {
            onSubmitting(values, employeeDocuments);
        },
    });

    const {
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        validateField,
        isValid,
        dirty,
        values,
    } = formik;

    React.useEffect(() => {
        const savedSubmitValues = localStorage.getItem('submit_values');
        const parsedSubmitValues = savedSubmitValues ? JSON.parse(savedSubmitValues) : {};
        setSubmitValues(parsedSubmitValues);
        setCredentialValues(parsedSubmitValues?.credentials);
        handleEmployeeValid(parsedSubmitValues?.credentials);
    }, []);

    const [isVerifyDialogOpen, setIsVerifyDialogOpen] = React.useState(false);

    const handleCloseDialog = () => {
        setIsVerifyDialogOpen(false);
    };

    const handleVerify = (otp: any) => {
        handleCloseDialog();
    };

    const handleResend = () => {};
    const handleEmployeeValid = (values: any) => {
        const formData = new FormData();
        formData.append('case_uid', values?.case_uid);
        formData.append('mobile_no', values?.mobile_no);
        Server.post(`/checkEmployeeValidLink`, formData)
            .then((res: any) => {
                if (res.data.meta.status === 200 && !res.data.meta.is_error) {
                    let employeeDocs = res?.data?.data?.employee_documents_data;
                    setEmployeeDocuments(employeeDocs);
                    localStorage.setItem('EmployeeData', JSON.stringify(employeeDataVal));
                    employeeOnlyVerifiedDocs = employeeDocuments
                        ? employeeDocuments.filter((item: any) => item.type_id == 4)
                        : [];
                }
            })
            .catch((err) => {});
    };

    const onUploadVerifyPhoto = (fileName: string, fileObjName: string, data: any) => {
        if (data) {
            setFieldValue(fileName, data?.url);
            setFieldValue(fileObjName, data);
        } else {
            setFieldValue(fileName, '');
            setFieldValue(fileObjName, null);
        }
        handleEmployeeValid(credentialValues);
    };

    const OnDeletingImage = (obj: any) => {
        handleEmployeeValid(credentialValues);
        if (values.verified_photo_obj_1 && values.verified_photo_obj_1.file_name == obj.file_name) {
            setFieldValue('verified_photo_obj_1', null);
            setFieldValue('verified_photo_1', '');
        } else if (
            values.verified_photo_obj_2 &&
            values.verified_photo_obj_2.file_name == obj.file_name
        ) {
            setFieldValue('verified_photo_obj_2', null);
            setFieldValue('verified_photo_2', '');
        }

        handleEmployeeValid(credentialValues);
    };

    let isSubmitDisabled = !(isValid && employeeOnlyVerifiedDocs.length >= 1);

    return (
        <>
            <FormikProvider value={formik}>
                <Form className="w-100" autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            maxWidth: '100%',
                            margin: 'auto',
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    autoComplete="VERIFIER_NAME"
                                    label="Name of person meet *"
                                    {...getFieldProps('VERIFIER_NAME')}
                                    error={Boolean(touched.VERIFIER_NAME && errors.VERIFIER_NAME)}
                                    helperText={
                                        touched.VERIFIER_NAME
                                            ? (errors.VERIFIER_NAME as string)
                                            : ''
                                    }
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    autoComplete="VERIFIER_CONTACT_NO"
                                    type="number"
                                    label="Contact Number *"
                                    {...getFieldProps('VERIFIER_CONTACT_NO')}
                                    error={Boolean(
                                        touched.VERIFIER_CONTACT_NO && errors.VERIFIER_CONTACT_NO,
                                    )}
                                    helperText={
                                        touched.VERIFIER_CONTACT_NO
                                            ? (errors.VERIFIER_CONTACT_NO as string)
                                            : ''
                                    }
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ marginTop: '0.5rem' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="verified-by-label">Verified By</InputLabel>
                                    <Select
                                        labelId="verified-by-label"
                                        {...getFieldProps('VERIFIED_BY')}
                                        label="Verified By"
                                        error={Boolean(touched.VERIFIED_BY && errors.VERIFIED_BY)}
                                    >
                                        {VERIFIED_BY_OPTIONS.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ marginTop: '0.5rem' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="verified-relation-label">
                                        Verified Person
                                    </InputLabel>
                                    <Select
                                        labelId="verified-relation-label"
                                        {...getFieldProps('VERIFIER_RELATION')}
                                        label="Verified Person"
                                        error={Boolean(
                                            touched.VERIFIER_RELATION && errors.VERIFIER_RELATION,
                                        )}
                                    >
                                        {VERIFIED_RELATION_OPTIONS.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
                                Verifier Photo Id
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <CustomFileUpload
                                            id="verified_photo_1"
                                            height={isMobile ? '12.5rem' : '11rem'}
                                            width={isMobile ? '100%' : '11rem'}
                                            title="Select Photo *"
                                            error={
                                                typeof errors.verified_photo_1 === 'string'
                                                    ? errors.verified_photo_1
                                                    : undefined
                                            }
                                            onChange={(event: any) =>
                                                onUploadVerifyPhoto(
                                                    'verified_photo_1',
                                                    'verified_photo_obj_1',
                                                    event,
                                                )
                                            }
                                            existingFile={values.verified_photo_1}
                                            vid={
                                                employeeDocuments[0]?.link_vid ??
                                                employeeData?.CASE_ID ??
                                                ''
                                            }
                                            fileType="verifier_photo_id"
                                            fileId={employeeDocuments[0]?.id ?? ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <CustomFileUpload
                                            id="verified_photo_2"
                                            height={isMobile ? '12.5rem' : '11rem'}
                                            width={isMobile ? '100%' : '11rem'}
                                            title="Select Photo"
                                            error={
                                                typeof errors.verified_photo_2 === 'string'
                                                    ? errors.verified_photo_2
                                                    : undefined
                                            }
                                            onChange={(event: any) =>
                                                onUploadVerifyPhoto(
                                                    'verified_photo_2',
                                                    'verified_photo_obj_2',
                                                    event,
                                                )
                                            }
                                            existingFile={values.verified_photo_2}
                                            vid={
                                                employeeDocuments[1]?.link_vid ??
                                                employeeData?.CASE_ID ??
                                                ''
                                            }
                                            fileType="verifier_photo_id"
                                            fileId={employeeDocuments[1]?.id ?? ''}
                                        />
                                    </Grid>
                                </Grid>
                                {employeeOnlyVerifiedDocs.length < 1 && (
                                    <span
                                        style={{
                                            color: '#d32f2f',
                                            fontWeight: '400',
                                            fontSize: '0.725rem',
                                        }}
                                    >
                                        At least one Verified Image is required
                                    </span>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={8}
                                style={{ height: '12.625rem', overflowY: 'auto' }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {employeeDocuments.length > 0 &&
                                            employeeDocuments
                                                .filter((item: any) => item.type_id == 4)
                                                .map((doc: any, index: number) => (
                                                    <div className="mb-2" key={index}>
                                                        <ImageItem
                                                            key={index}
                                                            employeeDocsData={doc}
                                                            onDeleteImage={OnDeletingImage}
                                                            isShown={isShown}
                                                        />
                                                    </div>
                                                ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Button
                            sx={{
                                position: 'fixed',
                                bottom: '1.875rem',
                                right: '1.875rem',
                            }}
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitDisabled}
                        >
                            NEXT
                        </Button>
                    </Box>
                </Form>
            </FormikProvider>

            <VerifyMobileDialog
                isOpen={isVerifyDialogOpen}
                contactNo={mobileNumber || ''}
                handleClose={handleCloseDialog}
                handleVerify={handleVerify}
                handleResend={handleResend}
            />
        </>
    );
};

export default VerfiedByForm;
