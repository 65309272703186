import React from 'react';
import {
    Card,
    CardContent,
    Stepper,
    Step,
    StepButton,
    useMediaQuery,
    useTheme,
} from '@mui/material';

interface StepperCardProps {
    activeStep: number;
    steps: string[];
    completed: boolean[];
    handleStep: (step: number) => () => void;
}

const StepperCard: React.FC<StepperCardProps> = ({ activeStep, steps, completed, handleStep }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Detect mobile screen size

    return (
        <Card
            variant="outlined"
            sx={{
                paddingLeft: isMobile ? '0' : '120px',
                paddingRight: isMobile ? '0' : '120px',
                display: isMobile ? 'flex' : '',
                justifyContent: isMobile ? 'center' : '',
                borderRadius: '10px',
                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                marginTop: '1.25rem',
                height: '5rem',
            }}
        >
            <CardContent>
                <Stepper nonLinear>
                    {isMobile ? (
                        <Step key={steps[activeStep]}>
                            <StepButton color="inherit" onClick={handleStep(activeStep)}>
                                {steps[activeStep]}
                            </StepButton>
                        </Step>
                    ) : (
                        steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton color="inherit" onClick={handleStep(index)}>
                                    {label}
                                </StepButton>
                            </Step>
                        ))
                    )}
                </Stepper>
            </CardContent>
        </Card>
    );
};

export default StepperCard;
