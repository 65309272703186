import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function ThankyouPage() {
    const [open, setOpen] = useState(true);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                style={{ maxHeight: isMobile ? '16rem' : '14rem', borderRadius: '0.25rem' }}
            >
                <DialogTitle
                    style={{
                        fontWeight: '700',
                        padding: '0.625rem 1rem',
                        backgroundColor: '#f8f8f8',
                        fontSize: isMobile ? '1.25rem' : '1.5rem',
                    }}
                >
                    General Digital Verification Link
                </DialogTitle>
                <DialogContent
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: isMobile ? '0.625rem 1rem' : '',
                    }}
                >
                    <CheckCircleOutlineIcon
                        style={{ height: '2rem', width: '2rem', color: '#78A75A' }}
                    />
                    <h5
                        className="ms-2"
                        style={{
                            height: isMobile ? '' : '2rem',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'end',
                        }}
                    >
                        Thank you for submitting your response.
                    </h5>
                </DialogContent>
            </Dialog>
        </>
    );
}
