import React from 'react';
import { useFormik, FormikProvider, Form, Field } from 'formik';
import { TextField } from '@mui/material';
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CustomFileUpload from '../components/CustomFileUpload';
import ImageItem from '../components/ImageItem';
import Server from '../api/index';

interface UploadPictureProofProps {
    employeeDataVal: any;
    onSubmitting: (formVal: any, data: any) => void;
    onPrevious: (step: number) => void;
}

const UploadPictureProofFormSchema = Yup.object().shape({
    selfie: Yup.string(),
    signature: Yup.string(),
    housePics1: Yup.string(),
    housePics2: Yup.string(),
    housePics3: Yup.string(),
    selfieObj: Yup.mixed().nullable(),
    signatureObj: Yup.mixed().nullable(),
    housePics1Obj: Yup.mixed().nullable(),
    housePics2Obj: Yup.mixed().nullable(),
    housePics3Obj: Yup.mixed().nullable(),
});

const UploadPictureProofForm: React.FC<UploadPictureProofProps> = ({
    employeeDataVal,
    onSubmitting,
    onPrevious,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const employeeData = employeeDataVal?.employee_case_data?.[0] || {};
    const [employeeDocuments, setEmployeeDocuments] = React.useState(
        employeeDataVal?.employee_documents_data || ([] as any),
    );
    const [selfieImg, setSelfieImg] = React.useState(
        employeeDocuments.find((item: any) => item.type_id == 1) || {},
    );
    const [signatureImg, setSignatureImg] = React.useState(
        employeeDocuments.find((item: any) => item.type_id == 2) || {},
    );
    let houseImg = employeeDocuments
        ? employeeDocuments.filter((item: any) => item.type_id == 3)
        : [];

    const [isShownSignature, setIsShownSignature] = React.useState<boolean>(true);
    const [isShownSelfie, setIsShownSelfie] = React.useState<boolean>(true);
    const [isShownHouse, setIsShownHouse] = React.useState<boolean>(true);
    const [submitValues, setSubmitValues] = React.useState({} as any);
    const [credentialValues, setCredentialValues] = React.useState({} as any);

    const formik = useFormik({
        initialValues: {
            selfie: selfieImg ? selfieImg?.url : '',
            signature: signatureImg ? signatureImg?.url : '',
            housePics1: houseImg[0] ? houseImg[0]?.url : '',
            housePics2: houseImg[1] ? houseImg[1]?.url : '',
            housePics3: houseImg[2] ? houseImg[2]?.url : '',
            selfieObj: selfieImg ?? null,
            signatureObj: signatureImg ?? null,
            housePics1Obj: houseImg[0] ?? null,
            housePics2Obj: houseImg[1] ?? null,
            housePics3Obj: houseImg[2] ?? null,
        },
        validationSchema: UploadPictureProofFormSchema,
        onSubmit: (values) => {
            onSubmitting(values, submitValues);
        },
    });

    React.useEffect(() => {
        const savedSubmitValues = localStorage.getItem('submit_values');
        const parsedSubmitValues = savedSubmitValues ? JSON.parse(savedSubmitValues) : {};
        setSubmitValues(parsedSubmitValues);
        setCredentialValues(parsedSubmitValues?.credentials);
        handleEmployeeValid(parsedSubmitValues?.credentials);
    }, []);

    const onUploadPhoto = (fileName: string, fileObjName: string, data: any) => {
        if (data) {
            setFieldValue(fileName, data?.url);
            setFieldValue(fileObjName, data);
        } else {
            setFieldValue(fileName, '');
            setFieldValue(fileObjName, null);
        }
        handleEmployeeValid(credentialValues);
    };

    const OnDeletingImage = (obj: any) => {
        handleEmployeeValid(credentialValues);
        if (values.housePics1Obj && values.housePics1Obj.file_name == obj.file_name) {
            setFieldValue('housePics1Obj', null);
            setFieldValue('housePics1', '');
        } else if (values.housePics2Obj && values.housePics2Obj.file_name == obj.file_name) {
            setFieldValue('housePics2Obj', null);
            setFieldValue('housePics2', '');
        } else if (values.housePics3Obj && values.housePics3Obj.file_name == obj.file_name) {
            setFieldValue('housePics3Obj', null);
            setFieldValue('housePics3', '');
        }
        houseImg = employeeDocuments.find((item: any) => item.type_id == 3);
    };

    const onDeletingSelfieImage = (obj: any) => {
        handleEmployeeValid(credentialValues);
        if (values.selfieObj && values.selfieObj.file_name == obj.file_name) {
            setFieldValue('selfieObj', null);
            setFieldValue('selfie', '');
        }
        setSelfieImg(employeeDocuments.find((item: any) => item.type_id == 1));
    };

    const onDeletingSignatureImage = (obj: any) => {
        handleEmployeeValid(credentialValues);
        if (values.signatureObj && values.signatureObj.file_name == obj.file_name) {
            setFieldValue('signatureObj', null);
            setFieldValue('signature', '');
        }
        setSignatureImg(employeeDocuments.find((item: any) => item.type_id == 2));
    };

    const handleEmployeeValid = (values: any) => {
        const formData = new FormData();
        formData.append('case_uid', values?.case_uid);
        formData.append('mobile_no', values?.mobile_no);
        Server.post(`/checkEmployeeValidLink`, formData)
            .then((res: any) => {
                if (res.data.meta.status === 200 && !res.data.meta.is_error) {
                    let employeeDocs = res?.data?.data?.employee_documents_data;
                    setEmployeeDocuments(employeeDocs);
                    localStorage.setItem('EmployeeData', JSON.stringify(employeeDataVal));
                    setSelfieImg(employeeDocs.find((item: any) => item.type_id == 1));
                    setSignatureImg(employeeDocs.find((item: any) => item.type_id == 2));
                    houseImg = employeeDocs.find((item: any) => item.type_id == 3);
                }
            })
            .catch((err) => {});
    };

    const {
        errors,
        touched,
        values,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        validateField,
        isValid,
        dirty,
    } = formik;

    React.useEffect(() => {
        if (!values.selfie) setIsShownSelfie(false);
        if (!values.signature) setIsShownSignature(false);
    }, [values, errors, touched, isValid, dirty]);

    const isSubmitDisabled = !(values.selfie && values.signature && houseImg.length >= 1);

    return (
        <FormikProvider value={formik}>
            <Form className="w-100" autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        maxWidth: '100%',
                        margin: 'auto',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={7}>
                            {!isMobile && (
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{
                                            fontWeight: 'bold',
                                            marginBottom: '0.5rem',
                                        }}
                                    >
                                        Verifier Selfie
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{
                                            fontWeight: 'bold',
                                            marginBottom: '0.5rem',
                                        }}
                                    >
                                        Verifier Signature
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container spacing={2}>
                                {isMobile && (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{
                                            fontWeight: 'bold',
                                            marginBottom: '0.5rem',
                                        }}
                                    >
                                        Verifier Selfie
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6}>
                                    <CustomFileUpload
                                        id="1"
                                        height={'12.5rem'}
                                        width={isMobile ? '100%' : '18rem'}
                                        title="Select Selfie Photo *"
                                        error={
                                            typeof errors.selfie === 'string'
                                                ? errors.selfie
                                                : undefined
                                        }
                                        onChange={(event: any) => {
                                            onUploadPhoto('selfie', 'selfieObj', event);
                                            setIsShownSelfie(true);
                                        }}
                                        existingFile={values.selfie}
                                        vid={selfieImg?.link_vid ?? employeeData?.CASE_ID ?? ''} // Pass the dynamic value or default
                                        fileType="fe_photo" // Pass the dynamic value or default
                                        fileId={selfieImg?.id ?? ''} // Pass the dynamic file ID
                                    />
                                    {selfieImg && (
                                        <div style={{ width: isMobile ? '100%' : '18rem' }}>
                                            <ImageItem
                                                key={'selfie'}
                                                employeeDocsData={selfieImg}
                                                onDeleteImage={onDeletingSelfieImage}
                                                isShown={isShownSelfie}
                                            />
                                        </div>
                                    )}
                                    {!values.selfie && (
                                        <span
                                            style={{
                                                color: '#d32f2f',
                                                fontWeight: '400',
                                                fontSize: '0.725rem',
                                            }}
                                        >
                                            Selfie Image is required
                                        </span>
                                    )}
                                </Grid>
                                {isMobile && (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        sx={{
                                            fontWeight: 'bold',
                                            marginBottom: '0.5rem',
                                        }}
                                    >
                                        Verifier Signature
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6}>
                                    <CustomFileUpload
                                        id="2"
                                        height={'12.5rem'}
                                        width={isMobile ? '100%' : '18rem'}
                                        title="Select Signature Photo*"
                                        error={
                                            typeof errors.signature === 'string'
                                                ? errors.signature
                                                : undefined
                                        }
                                        onChange={(event: any) => {
                                            onUploadPhoto('signature', 'signatureObj', event);
                                            setIsShownSignature(true);
                                        }}
                                        existingFile={values.signature}
                                        vid={signatureImg?.link_vid ?? employeeData?.CASE_ID ?? ''} // Pass the dynamic value or default
                                        fileType="signature" // Pass the dynamic value or default
                                        fileId={signatureImg?.id ?? ''} // Pass the dynamic file ID
                                    />
                                    <div style={{ width: isMobile ? '100%' : '18rem' }}>
                                        {signatureImg && (
                                            <ImageItem
                                                key={'signature'}
                                                employeeDocsData={signatureImg}
                                                onDeleteImage={onDeletingSignatureImage}
                                                isShown={isShownSignature}
                                            />
                                        )}
                                        {!values.signature && (
                                            <span
                                                style={{
                                                    color: '#d32f2f',
                                                    fontWeight: '400',
                                                    fontSize: '0.725rem',
                                                }}
                                            >
                                                Selfie Image is required
                                            </span>
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            Upload House Photos
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <CustomFileUpload
                                        id="3"
                                        height={isMobile ? '12.5rem' : '11rem'}
                                        width={isMobile ? '100%' : '11rem'}
                                        title="Select House Photo"
                                        error={
                                            typeof errors.housePics1 === 'string'
                                                ? errors.housePics1
                                                : undefined
                                        }
                                        onChange={(event: any) => {
                                            onUploadPhoto('housePics1', 'housePics1Obj', event);
                                        }}
                                        existingFile={values.housePics1}
                                        vid={houseImg[0]?.link_vid ?? employeeData?.CASE_ID ?? ''} // Pass the dynamic value or default
                                        fileType="house_photo" // Pass the dynamic value or default
                                        fileId={houseImg[0]?.id ?? ''} // Pass the dynamic file ID
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <CustomFileUpload
                                        id="4"
                                        height={isMobile ? '12.5rem' : '11rem'}
                                        width={isMobile ? '100%' : '11rem'}
                                        title="Select House Photo"
                                        error={
                                            typeof errors.housePics2 === 'string'
                                                ? errors.housePics2
                                                : undefined
                                        }
                                        onChange={(event: any) => {
                                            onUploadPhoto('housePics2', 'housePics2Obj', event);
                                        }}
                                        existingFile={values.housePics2}
                                        vid={houseImg[1]?.link_vid ?? employeeData?.CASE_ID ?? ''} // Pass the dynamic value or default
                                        fileType="house_photo" // Pass the dynamic value or default
                                        fileId={houseImg[1]?.id ?? ''} // Pass the dynamic file ID
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <CustomFileUpload
                                        id="5"
                                        height={isMobile ? '12.5rem' : '11rem'}
                                        width={isMobile ? '100%' : '11rem'}
                                        title="Select House Photo"
                                        error={
                                            typeof errors.housePics3 === 'string'
                                                ? errors.housePics3
                                                : undefined
                                        }
                                        onChange={(event: any) => {
                                            onUploadPhoto('housePics3', 'housePics3Obj', event);
                                        }}
                                        existingFile={values.housePics3}
                                        vid={houseImg[2]?.link_vid ?? employeeData?.CASE_ID ?? ''} // Pass the dynamic value or default
                                        fileType="house_photo" // Pass the dynamic value or default
                                        fileId={houseImg[2]?.id ?? ''} // Pass the dynamic file ID
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {!(houseImg.length >= 1) && (
                                        <span
                                            style={{
                                                color: '#d32f2f',
                                                fontWeight: '400',
                                                fontSize: '0.725rem',
                                            }}
                                        >
                                            At least one House Image is required
                                        </span>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ height: '10rem', overflowY: 'auto' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {employeeDocuments.length > 0 &&
                                        employeeDocuments
                                            .filter((item: any) => item.type_id == 3)
                                            .map((doc: any, index: number) => (
                                                <div className="mb-2" key={index}>
                                                    <ImageItem
                                                        key={index}
                                                        employeeDocsData={doc}
                                                        onDeleteImage={OnDeletingImage}
                                                        isShown={isShownHouse}
                                                    />
                                                </div>
                                            ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div
                        style={{
                            position: 'fixed',
                            bottom: '1.875rem',
                            right: '1.875rem',
                            display: 'flex',
                            gap: '3',
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            sx={{ mr: 1 }}
                            onClick={() => onPrevious(2)}
                        >
                            PREVIOUS
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isSubmitDisabled}
                        >
                            SUBMIT
                        </Button>
                    </div>
                </Box>
            </Form>
        </FormikProvider>
    );
};

export default UploadPictureProofForm;
