import React, { useEffect } from 'react';

interface TitleWrapperProps {
    title: string;
    children: React.ReactNode;
}

const TitleWrapper: React.FC<TitleWrapperProps> = ({ title, children }) => {
    useEffect(() => {
        document.title = title;
    }, [title]);

    return <>{title}</>;
};

export default TitleWrapper;
