import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Navigate, useRoutes } from 'react-router-dom';
import ThemeProvider from './theme';
import routes from './routes';
// import routes from './routes';
import { Button, createTheme } from '@mui/material';
// import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
// import { Theme } from '@mui/material/styles';

export default function App() {
    // let submitValues = localStorage.getItem('submit_values')
    //     ? JSON.parse(localStorage.getItem('submit_values') as string)
    //     : ({} as any);

    // const hasGeolocation =
    //     submitValues.geolocation &&
    //     submitValues.geolocation.LATITUDE &&
    //     submitValues.geolocation.LONGITUDE;

    // let isSubmitted = localStorage.getItem('isSubmitted')
    //     ? JSON.parse(localStorage.getItem('isSubmitted') as string)
    //     : false;

    // const isFormSubmitted: boolean = hasGeolocation && isSubmitted;

    const routing = useRoutes(routes(true));
    return <ThemeProvider>{routing}</ThemeProvider>;
}
