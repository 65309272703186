export const VERIFIED_BY_OPTIONS = [
    { value: 'Self & Family', label: 'Self & Family' },
    { value: 'Others', label: 'Others' },
    // Add more options as needed
];

export const VERIFIED_RELATION_OPTIONS = [
    { value: 'Self', label: 'Self' },
    { value: 'In-Laws', label: 'In-Laws' },
    { value: 'Uncle/Aunty', label: 'Uncle/Aunty' },
    { value: 'Parents', label: 'Parents' },
    { value: 'Brother/Sister', label: 'Brother/Sister' },
    { value: 'Wife/Husband', label: 'Wife/Husband' },
    { value: 'Son/Daughter', label: 'Son/Daughter' },
    // Add more options as needed
];

export const RESIDENTIAL_STATUS_OPTIONS = [
    { value: 'Owned', label: 'Own House' },
    { value: 'Rented', label: 'Rented House' },
    { value: 'Other', label: 'Allocated House' },
];
