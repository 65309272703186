import axios from 'axios';

const Server = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Language': 'browser',
        'Content-Type': 'multipart/form-data',
    },
});

// instance.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// Server.interceptors.request.use((config) => {
//     console.log('Request:', config);
//     return config;
// });

// Server.interceptors.response.use(
//     (response) => {
//         console.log('Response:', response);
//         return response;
//     },
//     (error) => {
//         console.log('Response Error:', error.response);
//         return Promise.reject(error);
//     },
// );

export default Server;
